<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div>
        <div class="wrap">
          <h1>Подсистема загрузки больших массивов данных</h1>
          <v-select
              label="Таблица"
              v-model="table"
              :items="tables"
              item-text="display_name"
              item-value="table_name"
              style="max-width: 300px"></v-select>
          <v-btn class="btn btn-outline" @click="downloadData()" style="margin-right: 20px">
            Экспорт (скачать данные)
          </v-btn>
          <v-btn class="btn btn-primary" @click="$refs['fileupload'].click()">
            Импорт (загрузить данные)
          </v-btn>
          <input type="file" ref="fileupload" style="display:none" @change="uploadData($event.target.files[0])">
        </div>
        <LoaderDialog :show="showLoaderDialog" :msg="loaderMsg"></LoaderDialog>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import api from "@/modules/api";
import LoaderDialog from "@/components/elements/LoaderDialog";

export default {
  props: {},
  components: { LoaderDialog },
  data() {
    return {
      loaderMsg: '',
      table: 'region',
      tables: [
        {
          table_name: 'region',
          display_name: 'Список субъектов РФ'
        },
        {
          table_name: 'abstract_department',
          display_name: 'Список ЦЗН'
        },
        {
          table_name: 'user_table',
          display_name: 'Список пользователей'
        },
        {
          table_name: 'anket_form_data',
          display_name: 'Анкеты'
        },
        {
          table_name: 'checklist_value',
          display_name: 'Показатели чеклистов'
        },
      ],
      showLoaderDialog: false,
    };
  },
  methods: {
    async downloadData(){
      this.loaderMsg='Подготовка файла'
      this.showLoaderDialog = true
      const fileName = this.table + '.xlsx'
      let req = await api.download('/dataload/download/' + this.table);
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }

      this.showLoaderDialog = false
    },

    async uploadData(file){
      this.loaderMsg='Обработка файла'
      this.showLoaderDialog = true
      let uploadRes = await this.uploadFile(file)
      if(uploadRes) {
        alert('Данные успешно импортированы')
      } else {
        alert('Ошибка при импорте данны')
      }
      this.showLoaderDialog = false
    },


    async uploadFile(file){
      if (file.size > 52428800) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        return null
      }
      let formData = new FormData();
      formData.append('file', file);
      let req = await api.postFile('/dataload/upload/' + this.table, {}, formData, 'POST');
      console.log('req', req)
      if (req.ok) {
        console.log('upload ok');
        return req.payload
      } else {
        alert('Ошибка загрузки файла: ' + req.error)
        console.log('upload error', req.error)
      }
    },
  },
  async beforeMount() {

  }
};
</script>
